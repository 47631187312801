import React from "react"
import styles from "./navigation.module.css"
import { Link } from "gatsby"

export default function Navigation() {
  return (
    <nav className={styles.nav}>
      <Link to="/" title="Home" className={styles.homeLink}>E<span>rica</span> N<span>orby</span></Link>
      <Link to="/experience" className={styles.link} activeClassName={styles.active}>Experience</Link>
      <Link to="/projects" className={styles.link} activeClassName={styles.active}>Projects</Link>
    </nav>
  )
}
