import React from "react"
import { ThemeContext } from "../provider.js"
import Head from "../components/head"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import styles from "./experience.module.css"
import ThemeToggle from "../components/theme-toggle"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

export default function Experience() {
  return (
    <ThemeContext.Consumer>
      {context => (
        <div id="page" className={context.isDark ? "darkTheme" : ""}>
          <Head />
          <SEO title="Experience" />
          <main>

            <header id="page-header" className="maxWidth flex">
              <ThemeToggle />
              <Navigation />
            </header>

            <div id="content" className="maxWidth">
              <h1>Experience & Certifications</h1>

              <div className={styles.item}>
                <div className={styles.dates}>Jan 2021 - Present</div>
                <div className={styles.details}>
                  <h2>Software Engineer</h2>
                  <div className={styles.company}>Booz Allen Hamilton</div>
                  <p className={styles.tech}>Vue, Javascript, HTML, CSS</p>
                  <p></p>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.dates}>May 2017 - Dec 2020</div>
                <div className={styles.details}>
                  <h2>Web Developer</h2>
                  <div className={styles.company}>Zurka Interactive</div>
                  <p className={styles.tech}>Laravel, Drupal 8 & 7, PHP, Javascript, HTML, CSS, SCSS</p>
                  <p>At Zurka, I built responsive full stack web applications that were customized according to clients' needs. I gained experience with module development, theming, responsive design & accessibility, e-commerce, payment processing, API integration, and more.</p>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.dates}>Jan 2017 - Apr 2017</div>
                <div className={styles.details}>
                  <h2>Web Development Immersive Student</h2>
                  <div className={styles.company}>General Assembly</div>
                  <p className={styles.tech}>React, AngularJS, Ruby on Rails, Javascript, HTML, CSS</p>
                  <p>I completed a 3-month full stack web development course to get started in the field. I enjoyed learning the basics and working with many different languages and frameworks.</p>
                </div>
              </div>

            </div>

            <div className="contentRow">
              <div className="maxWidth">
                <div data-iframe-width="280" data-iframe-height="242" data-share-badge-id="9dc6258e-ce83-4a64-ab71-5f38785a41cc" data-share-badge-host="https://www.youracclaim.com"></div>
                <Helmet>
                  <script type="text/javascript" async src="//cdn.youracclaim.com/assets/utilities/embed.js"></script>
                </Helmet>
              </div>
            </div>

          </main>

          <Footer />

        </div>
      )}
    </ThemeContext.Consumer>
  )
}
